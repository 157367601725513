.NavBar {
  width: 100%;
  height: 60px;
  display: flex;
  background-color: #AED581;
}

.links-container{
  flex-grow: 1;
  height: 60px;
  display: flex;
  justify-content: space-around;

}

.link-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}


.App-logo {
  height: 4vmin;
  width: 4vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; 
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
